import React from 'react'
import PropTypes from 'prop-types'
import Section from 'components/Section/headerSection'

const HeaderSection = ({ elements: { title, tagline, transitioned_text } }) => {
  var transitionedArr = []
  if (transitioned_text.value !== '') {
    transitionedArr = transitioned_text.value.split('/')
  }

  return (
    <Section
      header
      headline={title.value}
      tagline={tagline.value}
      transitionedText={transitionedArr}
      richText
    />
  )
}

export default HeaderSection

HeaderSection.propTypes = {
  elements: PropTypes.object,
}
