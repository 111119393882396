import React from 'react'
import PropTypes from 'prop-types'
import { color } from 'const/colors'
import Section from 'components/Section'
import PageTransitionLink from 'components/PageTransitionLink'
import {
  H2,
  H1,
  ServiceDescription,
  SectionContent,
  Wrapper,
  Sidebar,
  PageSectionStyled,
} from './styles'
import styled from 'styled-components'
import Link from 'components/Link'
import media from 'styles/media'

if (typeof window !== 'undefined') {
  require('smooth-scroll')('a[href*="#"]') // eslint-disable-line global-require
}

export const LinkStyled = styled(Link)`
  font-size: 20px;
  color: darkgray;
  ${media.tablet`
    font-size: 20px;
  `}
  &:hover {
    color: black;
    opacity: 0.8;
  }
`

const renderSection = (serviceItems) =>
  serviceItems.map(
    ({ elements: { title, preview_image, preview_text, slug } }, i) => {
      return (
        <div key={i}>
          <H1 isFirst={i === 0} id={`${createAnchorId(title.value)}`}>
            {title.value}
          </H1>
          <img src={preview_image.value[0].url} />

          <ServiceDescription>
            {preview_text.value.replace(/<\/?p>/g, '')}
          </ServiceDescription>
          <PageTransitionLink to={`/services/${slug.value}`}>
            <H2>Read more →</H2>
          </PageTransitionLink>
        </div>
      )
    }
  )

const createAnchorId = (str) => {
  return str.toLowerCase().replace(/\s+/g, '-')
}

class ServicesSection extends React.Component {
  state = {
    currentLink: '',
    mainNavLinks: [],
    servicesSidebar: null,
    underlineColors: {
      design: color.blue.o100,
      development: color.green.o100,
      leadership: color.purple.o100,
    },
  }

  sectionScroller = () => {
    let fromTop = window.scrollY

    this.state.mainNavLinks.forEach((link) => {
      let section = document.querySelector(link.hash)
      if (
        section.offsetTop <= fromTop &&
        section.offsetTop + section.offsetHeight > fromTop
      ) {
        this.setState({ currentLink: link.hash })
      }
    })
    if (
      this.state.servicesSidebar.offsetTop +
        this.state.servicesSidebar.offsetHeight <
      fromTop
    ) {
      this.setState({ currentLink: '' })
    }
  }

  componentDidMount() {
    this.setState({
      mainNavLinks: document.querySelectorAll('div.servicesSidebar ul li a'),
      servicesSidebar: document.querySelector('div.servicesSidebar'),
    })
    // window.addEventListener('scroll', this.sectionScroller)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.sectionScroller)
  }

  render() {
    const { service_items } = this.props.elements

    return (
      <PageSectionStyled>
        <Sidebar className="servicesSidebar">
          <ul>
            {service_items.map((item, i) => (
              <li key={i}>
                <LinkStyled
                  to={`#${createAnchorId(item.elements.title.value)}`}
                  externalLink
                  underline
                  color={
                    this.state.currentLink !== '#design-section' &&
                    color.black.o25
                  }
                  lineColor={
                    this.state.currentLink === '#design-section'
                      ? this.state.underlineColors.design
                      : color.transparent
                  }
                >
                  {item.elements.title.value}
                </LinkStyled>
              </li>
            ))}
          </ul>
        </Sidebar>
        <SectionContent>
          <Wrapper id="design-section">
            <Section>{renderSection && renderSection(service_items)}</Section>
          </Wrapper>
        </SectionContent>
      </PageSectionStyled>
    )
  }
}

ServicesSection.propTypes = {
  elements: PropTypes.object,
  currentBreakpoint: PropTypes.string,
}

export default ServicesSection
