import styled from 'styled-components'
import Link from 'components/Link'
import media from 'styles/media'
import PageSection from 'components/Section/pageSection'
import { color } from 'const/colors'

export const Wrap = styled.div`
  margin-right: 30px;

  ${media.phone`
    margin-right: 0;
  `}
`

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const H1 = styled.h1`
  margin: 66px 0 25px;
  padding: 0;
  font-weight: 400;
  font-size: 36px;
  line-height: 48px;
  margin-top: ${({ isFirst }) => isFirst && 0};

  ${media.tablet`
    font-size: 24px;
  `}

  ${media.phone`
    margin-bottom: 40px;
  `}
`

export const H2 = styled.h2`
  margin: 7px 0 10px;
  font-size: 24px;
  line-height: 40px;
  font-weight: 400;
  color: ${color.black.o100};

  ${media.tablet`
    font-size: 20px;
    line-height: 28px;
  `}
`

export const LinkStyled = styled(Link)`
  font-size: 24px;

  ${media.tablet`
    font-size: 20px;
  `}
`

export const Description = styled.p`
  font-size: 20px;
  line-height: 28px;
  color: ${color.black.o25};
  margin-top: 0;
  margin-bottom: 74px;

  ${media.tablet`
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 40px;
  `}
`

export const ServiceDescription = styled.p`
  font-size: 20px;
  line-height: 28px;
  color: ${color.black.o25};
  margin-top: 0;
  margin-bottom: 10px;

  ${media.tablet`
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 40px;
  `}
`

export const ServiceDescriptionForMobile = styled.p`
  font-size: 20px;
  line-height: 28px;
  color: ${color.black.o25};
  margin-top: 0;
  margin-bottom: 10px;
  width: 60%;

  ${media.tablet`
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 40px;
  `}
`

export const FormattedText = styled.pre`
  font-size: 20px;
  line-height: 28px;
  color: ${color.black.o25};
  margin-top: 0;
  margin-bottom: 74px;

  ${media.tablet`
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 40px;
  `}
`

export const Sidebar = styled.div`
  display: flex;
  flex-flow: column nowrap;
  /* align-items: center; */
  min-height: 100vh;
  width: 40%;

  ul {
    padding-left: 20%;
    position: sticky;
    top: 20px;
    list-style: none;
    /* padding: 0; */

    li {
      margin-bottom: 16px;

      a {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
      }
    }
  }

  ${media.tablet`
    width: 30%;
  `}
`

export const PageSectionStyled = styled(PageSection)`
  display: flex;
  flex-flow: row;
  margin-top: 100px;
  margin-bottom: 200px;
  max-width: 1366px;
  width: 95%;

  ${media.tablet`
    margin-top: 0;
    width: 100%;
  `}
`
