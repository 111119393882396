import styled from 'styled-components'
import { color } from 'const/colors'

const Tab = styled.span`
  height: auto;
  color: ${({ active }) => (active ? color.black.o100 : color.black.o25)};
  border-bottom: ${({ active, borderBottomColor }) =>
    active && borderBottomColor ? `2px solid ${borderBottomColor}` : 'none'};
  border-bottom: ${({ inactiveTab }) =>
    inactiveTab && `2px solid ${color.black.o5}`};
  cursor: pointer;
  line-height: 20px;
  font-size: 11px;
  padding: 9px 0;
  text-align: center;
  min-width: 80px;
`

export default Tab
