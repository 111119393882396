import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Tab from 'components/Tab'
import { Flex } from '@rebass/grid'
import { color } from 'const/colors'
import { H2, H1, ServiceDescriptionForMobile } from './styles'
import PageTransitionLink from 'components/PageTransitionLink'

const Section = styled.div`
  padding: 0 24px 50px 24px;
`

const TabsWrapper = styled(Flex)`
  margin: 100px 0 70px 0;
  width: 100%;
  overflow: auto;

  -ms-overflow-style: none;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    display: none;
    height: 0 !important;
    width: 0 !important;
  }

  & > span:nth-child(1n) {
    padding-left: calc((100vw - 404px) / 2);
  }

  & > span:nth-child(2n) {
    padding-left: 10px;
    padding-right: 10px;
  }

  & > span:nth-child(3n) {
    padding-right: calc((100vw - 404px) / 2);
  }
`

const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
`

const createAnchorId = (str) => {
  return str.toLowerCase().replace(/\s+/g, '-')
}

class MobileSection extends React.Component {
  state = {
    activeTab: this.props.elements.service_items[0].elements.title.value,
  }

  changeActiveTab = (activeTab) => {
    this.setState({ activeTab: activeTab })
  }

  renderTabs = (serviceItems) =>
    serviceItems.map(({ elements: { title } }, i) => {
      return (
        <Tab
          key={i}
          onClick={() => this.changeActiveTab(title.value)}
          active={this.state.activeTab == title.value}
          borderBottomColor={color.blue.o100}
          inactiveTab={this.state.activeTab != title.value}
        >
          {title.value}
        </Tab>
      )
    })

  renderSections = (serviceItems) =>
    serviceItems.map(
      ({ elements: { title, preview_image, preview_text, slug } }, i) => {
        return (
          this.state.activeTab == title.value && (
            <TabContent key={i}>
              <H1 isFirst={i === 0} id={`${createAnchorId(title.value)}`}>
                {title.value}
              </H1>
              <img src={preview_image.value[0].url} width={350} />

              <ServiceDescriptionForMobile>
                {preview_text.value.replace(/<\/?p>/g, '')}
              </ServiceDescriptionForMobile>
              <PageTransitionLink to={`/services/${slug.value}`}>
                <H2>Read more →</H2>
              </PageTransitionLink>
            </TabContent>
          )
        )
      }
    )

  render() {
    const { service_items } = this.props.elements
    return (
      <>
        <TabsWrapper>{this.renderTabs(service_items)}</TabsWrapper>
        <Section>{this.renderSections(service_items)}</Section>
      </>
    )
  }
}

MobileSection.propTypes = {
  elements: PropTypes.object,
}

export default MobileSection
