import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from 'layouts/Default'
import HeaderSection from 'sections/Services/header'
import ContactSection from 'sections/Shared/ContactSection'
import ServicesSection from 'sections/Services/services'
import MobileSection from 'sections/Services/mobile'
import withCurrentBreakpoint from 'hoc/withCurrentBreakpoint'

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ServicesSectionStyled = styled(ServicesSection)`
  min-height: 100vh;
`

class Services extends React.Component {
  state = {
    isContactFormModalOpen: false,
  }

  toggleScrollBody = (isContactFormModalOpen) => {
    this.setState({
      isContactFormModalOpen,
    })
  }

  buildOGMetadata() {
    const { elements } = this.props.data.kenticoCloudItemServices

    return {
      title: elements.page_metadata__opengraph_title.value,
      imageUrl: elements.page_metadata__opengraph_image.value[0].url,
      description: elements.page_metadata__opengraph_description.value,
    }
  }

  render() {
    const { isContactFormModalOpen } = this.state
    const { data, currentBreakpoint, ...restProps } = this.props

    return (
      <Layout
        isContactFormModalOpen={isContactFormModalOpen}
        {...restProps}
        ogMetadata={this.buildOGMetadata()}
      >
        <Helmet>
          <link
            rel="canonical"
            href={`${process.env.GATSBY_ROBOTS_SITE_URL}/services`}
          />
        </Helmet>
        <PageContainer {...this.props}>
          <HeaderSection {...data.kenticoCloudItemServices} />
          {currentBreakpoint === 'phone' && (
            <MobileSection {...data.kenticoCloudItemServices} />
          )}
          {(currentBreakpoint === 'tablet' ||
            currentBreakpoint === 'desktop' ||
            currentBreakpoint === 'desktopLarge') && (
            <ServicesSectionStyled {...data.kenticoCloudItemServices} />
          )}
        </PageContainer>
        <ContactSection toggleScrollBody={this.toggleScrollBody} />
      </Layout>
    )
  }
}

export default withCurrentBreakpoint(Services)

Services.propTypes = {
  data: PropTypes.object,
  currentBreakpoint: PropTypes.string,
}

export const query = graphql`
  {
    kenticoCloudItemServices {
      elements {
        title {
          value
        }
        transitioned_text {
          value
        }
        tagline {
          value
        }
        service_items {
          elements {
            title {
              value
            }
            preview_image {
              value {
                url
              }
            }
            preview_text {
              value
            }
            slug {
              value
            }
            body_text {
              value
            }
          }
        }
        breakout_url {
          value
        }
        page_metadata__opengraph_title {
          value
        }
        page_metadata__opengraph_image {
          value {
            url
          }
        }
        page_metadata__opengraph_description {
          value
        }
      }
    }
  }
`
